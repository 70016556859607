
.break-after {
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 100px;
}
@media print {
    .break-after {
        page-break-after: always;
        /* Force page break after each section */
    }
}

table.my-table {
font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table.my-table>thead>tr>th {
border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

table.my-table>tbody>tr>td {
border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

table.my-table>tbody>tr:nth-child(even) {
    background-color: #eeeeee;
}
